import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Monica Setyo Hertuti (Tjan Djien Nio)';
export const GIRL_NAME_SHORT = 'Monica';
export const GIRL_FATHER_NAME = `Parents Man`;
export const GIRL_MOTHER_NAME = `Parents Lady`;
export const GIRL_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The daughter of</b><br /> Mr. ${GIRL_FATHER_NAME} <br /> and Mrs. ${GIRL_MOTHER_NAME}`
    : `<b>Monica Setyo Hertuti</b> <br />(Tjan Djien Nio)`
  ;

// -> boy section
export const BOY_NAME = 'F.X. Setyo Prihandoko (Sie Ping An)';
export const BOY_NAME_SHORT = 'Handoko';
export const BOY_FATHER_NAME = `Parents Man`;
export const BOY_MOTHER_NAME = `Parents Lady`;
export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `<b>The son of</b><br /> Mr. ${BOY_FATHER_NAME} <br /> and Mrs. ${BOY_MOTHER_NAME}`
    : `<b>F.X. Setyo Prihandoko</b> <br />(Sie Ping An)`
  ;

// -> bride section
export const BRIDE_HASHTAG = ``;
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = '';
export const IG_GIRL = '';
export const IG_FILTER_URL = `https://www.instagram.com/ar/1760074247768820/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/bxwgcojbe/50th-handoko-monica/seo.jpg?updatedAt=1698741038404`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `50th Anniversary of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan ulang tahun pernikahan kami yang ke-50. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1700906400;
export const EPOCH_RESEPSI_START = 1700906400;
export const EPOCH_END_EVENT = 1700917200;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-11-25');
export const DATE_RESEPSI = new Date('2023-11-25');

export const WEDDING_AKAD_TIME = '17.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Thamrin Square';
export const WEDDING_AKAD_LOC_ROAD = `Jl. MH Thamrin No. 5, Sekayu, Kec. Semarang Tengah, Kota Semarang, Jawa Tengah`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
.locale(DEFAULT_LANGUAGE)
.format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_AKAD_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;

export const WEDDING_RESEPSI_TIME = '18.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = 'Thamrin Square';
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. MH Thamrin No. 5, Sekayu, Kec. Semarang Tengah, Kota Semarang, Jawa Tengah`;
export const WEDDING_RESEPSI_DRESSCODE = ``;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
.locale(DEFAULT_LANGUAGE)
.format('dddd, DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_RESEPSI_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;
export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD • MMMM • YYYY');
export const IS_SAME_LOCATION =
  WEDDING_AKAD_LOC_NAME === WEDDING_RESEPSI_LOC_NAME &&
  WEDDING_AKAD_LOC_ROAD === WEDDING_RESEPSI_LOC_ROAD;

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://50th-anniversary-handoko-monica.com/';
export const BROADCAST_WEDDING_LOCATION = `Thamrin square, Kota Semarang`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_AKAD = `https://maps.app.goo.gl/kFQeHyMM28qGxR3o8`;
export const GOOGLE_MAPS_RESEPSI = `https://maps.app.goo.gl/kFQeHyMM28qGxR3o8`;
export const GOOGLE_MAPS_ADDRESS = `Thamrin Square Semarang`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Only You by Sidney Mohede & Andi Irawan`;
export const SOUND_URL = 'https://youtu.be/K_svgyLLwgw?si=6ko7QkMnh6ME1BWT';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';
export const PREWEDDING_LINK = 'https://youtu.be/gp9uomY7k-Q';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/gp9uomY7k-Q';

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbzy4VAJKQFdBPFkqFfHcpUndTxEgnqw_UOs1-ge-WkOBAuCZTm2-fYmcSysxDL4CURphg/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const INVITATO_URL = 'https://invitato.id';
export const INVITATO_IG_URL = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';